// src/components/ManageMangas.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../style/ManageMangas.css';

function ManageMangas() {
  const [mangas, setMangas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 16; // Número de mangás por página

  useEffect(() => {
    fetch('https://api.arthurcomics.com/api/mangas')
      .then(response => response.json())
      .then(data => setMangas(data))
      .catch(error => console.error('Erro ao buscar mangás:', error));
  }, []);

  // Calcular os índices dos mangás a serem exibidos na página atual
  const indexOfLastManga = currentPage * itemsPerPage;
  const indexOfFirstManga = indexOfLastManga - itemsPerPage;
  const currentMangas = mangas.slice(indexOfFirstManga, indexOfLastManga);

  // Calcular o número total de páginas
  const totalPages = Math.ceil(mangas.length / itemsPerPage);

  // Função para mudar a página
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="manga-list-container">
      <h1>Gerenciar Mangás</h1>
      <div className="manga-grid">
        {currentMangas.map(manga => (
          <div key={manga._id} className="manga-item">
            <img
              src={`https://api.arthurcomics.com/${manga.coverImage}`}
              alt={manga.title}
              className="manga-cover"
            />
            <h3>{manga.title}</h3>
            <Link to={`/projetos/${manga._id}`} className="manage-button">
              Gerenciar
            </Link>
          </div>
        ))}
      </div>
      {/* Navegação de páginas */}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {'<'}
        </button>

        {/* Renderizar números das páginas e reticências */}
        {currentPage > 2 && (
          <>
            <button onClick={() => handlePageChange(1)}>1</button>
            {currentPage > 3 && <span>...</span>}
          </>
        )}

        {Array.from({ length: totalPages }, (_, index) => {
          const pageNumber = index + 1;
          if (pageNumber === currentPage - 1 || pageNumber === currentPage || pageNumber === currentPage + 1) {
            return (
              <button
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                className={currentPage === pageNumber ? 'active' : ''}
              >
                {pageNumber}
              </button>
            );
          }
          return null;
        })}

        {currentPage < totalPages - 1 && (
          <>
            {currentPage < totalPages - 2 && <span>...</span>}
            <button onClick={() => handlePageChange(totalPages)}>{totalPages}</button>
          </>
        )}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          {'>'}
        </button>
      </div>
    </div>
  );
}

export default ManageMangas;
