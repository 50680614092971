import React, { useState } from 'react';

const Register = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Limpa qualquer erro anterior

    if (password !== confirmPassword) {
      setError('As senhas não coincidem');
      return;
    }

    const payload = { username, email, password };

    console.log('Enviando dados:', payload); // Log dos dados enviados

    try {
      const response = await fetch('https://api.arthurcomics.com/auth/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      console.log('Status da resposta:', response.status); // Log do status da resposta
      const data = await response.json();
      console.log('Dados da resposta:', data); // Log dos dados recebidos

      if (response.ok) {
        alert('Usuário registrado com sucesso!');
      } else {
        setError(data.error || 'Erro ao registrar usuário');
      }
    } catch (error) {
      console.error('Erro ao registrar:', error);
      setError('Erro ao registrar. Tente novamente.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Registrar Usuário</h2>
      {error && <p className="error-message">{error}</p>} {/* Exibe a mensagem de erro */}
      <input
        type="text"
        placeholder="Nome de Usuário"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required
      />
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <input
        type="password"
        placeholder="Senha"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <input
        type="password"
        placeholder="Confirme a Senha"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
      />
      <button type="submit">Registrar</button>
    </form>
  );
};

export default Register;
