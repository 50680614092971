// src/components/Dashboard.js
import { jwtDecode } from 'jwt-decode';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../style/Dashboard.css';

function Dashboard() {
  const navigate = useNavigate();
  const token = localStorage.getItem('authToken');

  // Decodifica o token para obter as informações do usuário
  let userName = '';
  if (token) {
    const decodedToken = jwtDecode(token);
    userName = decodedToken.email; // Ou outro campo que você usa para armazenar o nome
  }

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/login'); // Redireciona para a página de login
  };

  return (
    <div className="dashboard-container">
      <h1 className="dashboard-header">Painel de Administração</h1>
      <p className="dashboard-user">Bem-vindo, {userName}!</p>
      <div className="dashboard-actions">
      <Link to="/edit-site-info">
          <button className="dashboard-button">Editar Informações do Site</button>
        </Link>
        <Link to="/create-manga">
          <button className="dashboard-button">Criar Mangá</button>
        </Link>
        <Link to="/projetos">
          <button className="dashboard-button">Gerenciar Mangás</button>
        </Link>
        <Link to="/manage-header">
          <button className="dashboard-button">Gerenciar Cabeçalho</button>
        </Link>
        <button className="dashboard-button" onClick={handleLogout}>
          Sair
        </button>
      </div>
    </div>
  );
}

export default Dashboard;
