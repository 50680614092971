// src/App.js
import React, { useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import './App.css';
import AdManagement from './components/AdManagement';
import AdminHeader from './components/AdminHeader';
import CreateChapter from './components/CreateChapter';
import CreateManga from './components/CreateManga';
import Dashboard from './components/Dashboard';
import EditManga from './components/EditManga';
import EditSiteInfo from './components/EditSiteInfo';
import ManageHeader from './components/ManageHeader';
import ManageMangas from './components/ManageMangas';
import Login from './login/Login';
import Register from './login/Register';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('authToken'));

  const handleLogin = (token) => {
    localStorage.setItem('authToken', token);
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
  };

  return (
    <Router>
      {isAuthenticated && <ConditionalHeader onLogout={handleLogout} />}
      <Routes>
        <Route path="/dashboard" element={isAuthenticated ? <Dashboard /> : <Navigate to="/login" />} />
        <Route path="/create-manga" element={isAuthenticated ? <CreateManga /> : <Navigate to="/login" />} />
        <Route path="/create-chapter" element={isAuthenticated ? <CreateChapter /> : <Navigate to="/login" />} />
        <Route path="/projetos" element={isAuthenticated ? <ManageMangas /> : <Navigate to="/login" />} />
        <Route path="/projetos/:name" element={isAuthenticated ? <EditManga /> : <Navigate to="/login" />} />
        <Route path="/manage-header" element={isAuthenticated ? <ManageHeader /> : <Navigate to="/login" />} />
        <Route path="/publicidade" element={<AdManagement />} />
        <Route path="/edit-site-info" element={isAuthenticated ? <EditSiteInfo /> : <Navigate to="/login" />} />
        <Route path="/login" element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login onLogin={handleLogin} />} />
        <Route path="/register" element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <Register />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </Router>
  );
}

function ConditionalHeader({ onLogout }) {
  const location = useLocation();

  // Condição para ocultar o AdminHeader no dashboard
  if (location.pathname === '/dashboard') {
    return null;
  }

  return <AdminHeader onLogout={onLogout} />;
}

export default App;
