import React, { useEffect, useState } from 'react';

function ManageHeader() {
  const [menus, setMenus] = useState([]);
  const [newMenu, setNewMenu] = useState({ label: '', url: '', icon: '' }); // Adicionando campo de ícone
  const [message, setMessage] = useState('');
  const [editingMenuId, setEditingMenuId] = useState(null); // Estado para menu em edição

  useEffect(() => {
    fetchMenus();
  }, []);

  const fetchMenus = async () => {
    try {
      const response = await fetch('https://api.arthurcomics.com/api/menus');
      const data = await response.json();
      setMenus(data || []);
    } catch (error) {
      console.error('Erro ao carregar menus:', error);
      setMessage('Erro ao carregar menus.');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMenu({ ...newMenu, [name]: value });
  };

  const handleAddOrUpdateMenu = async (e) => {
    e.preventDefault();
    try {
      if (editingMenuId) {
        // Editar menu existente
        const response = await fetch(`https://api.arthurcomics.com/api/menus/${editingMenuId}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(newMenu),
        });
        const data = await response.json();
        if (data && data._id) {
          setMenus(menus.map(menu => (menu._id === editingMenuId ? data : menu)));
          setMessage('Menu editado com sucesso!');
        } else {
          setMessage('Erro ao editar menu.');
        }
      } else {
        // Adicionar novo menu
        const response = await fetch('https://api.arthurcomics.com/api/menus', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(newMenu),
        });
        const data = await response.json();
        if (data && data._id) {
          setMenus([...menus, data]);
          setMessage('Menu adicionado com sucesso!');
        } else {
          setMessage('Erro ao adicionar menu.');
        }
      }
      setNewMenu({ label: '', url: '', icon: '' }); // Limpa todos os campos
      setEditingMenuId(null); // Reseta o ID de edição
    } catch (error) {
      setMessage('Erro ao adicionar/editar menu.');
      console.error('Erro ao adicionar/editar menu:', error);
    }
  };

  const handleEditMenu = (menu) => {
    setNewMenu({ label: menu.label, url: menu.url, icon: menu.icon }); // Preenche os campos com os dados do menu
    setEditingMenuId(menu._id); // Define o ID do menu que está sendo editado
  };

  const handleDeleteMenu = async (id) => {
    try {
      const response = await fetch(`https://api.arthurcomics.com/api/menus/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        setMenus(menus.filter(menu => menu._id !== id));
        setMessage('Menu excluído com sucesso!');
      } else {
        setMessage('Erro ao excluir menu.');
      }
    } catch (error) {
      setMessage('Erro ao excluir menu.');
      console.error('Erro ao excluir menu:', error);
    }
  };

  return (
    <div className="manage-header-container">
      <h1>Gerenciar Menus do Header</h1>
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleAddOrUpdateMenu}>
        <label>
          Nome do Menu:
          <input
            type="text"
            name="label"
            placeholder="Nome do Menu"
            value={newMenu.label}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          URL do Menu:
          <input
            type="text"
            name="url"
            placeholder="URL do Menu"
            value={newMenu.url}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Ícone ou Imagem:
          <input
            type="text"
            name="icon"
            placeholder="URL do Ícone ou Imagem (opcional)"
            value={newMenu.icon}
            onChange={handleInputChange}
          />
        </label>
        <button type="submit">{editingMenuId ? 'Atualizar Menu' : 'Adicionar Menu'}</button>
      </form>
      <ul className="menu-list">
        {menus.map(menu => (
          <li key={menu._id}>
            {menu.icon && (menu.icon.startsWith('http') ? (
              <img src={menu.icon} alt={menu.label} style={{ width: '20px', height: '20px', marginRight: '5px' }} />
            ) : (
              <i className={menu.icon} style={{ marginRight: '5px' }}></i>
            ))}
            {menu.label} - {menu.url}
            <button onClick={() => handleEditMenu(menu)}>Editar</button>
            <button onClick={() => handleDeleteMenu(menu._id)}>Excluir</button>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ManageHeader;
