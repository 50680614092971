// src/components/AdminHeader.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import userIcon from '../assets/user-icon.png'; // Importe a imagem de usuário
import '../style/AdminHeader.css';

function AdminHeader({ onLogout }) {
  const navigate = useNavigate();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    onLogout(); // Chama a função de logout do pai, se necessário
    navigate('/login');
  };

  // Alterna a visibilidade do menu dropdown
  const toggleDropdown = () => {
    setIsDropdownVisible(prev => !prev);
  };

  // Fecha o dropdown ao clicar fora do menu
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.user-menu') && isDropdownVisible) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isDropdownVisible]);

  return (
    <header className="admin-header">
      <div className="admin-header-logo">
        <Link to="/dashboard">Admin Panel</Link>
      </div>
      <nav className={`admin-header-nav ${isDropdownVisible ? 'active' : ''}`}>
        <Link to="/dashboard">Dashboard</Link>
        <Link to="/create-manga">Criar Mangá</Link>
        <Link to="/create-chapter">Criar Capítulo</Link>
        <Link to="/projetos">Gerenciar Mangás</Link>
        <Link to="/manage-header">Gerenciar Header</Link>
        <Link to="/publicidade">Gerenciar Anúncios</Link> {/* Adicione a rota de gerenciamento de anúncios */}
      </nav>
      <div className="user-menu">
        <img src={userIcon} alt="User" className="user-icon" onClick={toggleDropdown} />
        {isDropdownVisible && (
          <div className="dropdown-menu">
            <button onClick={handleLogout}>Logout</button>
          </div>
        )}
      </div>
    </header>
  );
}

export default AdminHeader;
