import React, { useEffect, useState } from 'react';

const token = localStorage.getItem('authToken');

const EditSiteInfo = () => {
  const [siteInfo, setSiteInfo] = useState({
    title: '',
    description: '',
    icon: '',
    logo: ''
  });

  const [loadingError, setLoadingError] = useState(null);

  useEffect(() => {
    // Função para buscar as informações do site
    const fetchSiteInfo = async () => {
      try {
        const response = await fetch('https://api.arthurcomics.com/api/site-info');
        if (!response.ok) {
          throw new Error('Erro ao buscar informações do site');
        }
        const data = await response.json();
        setSiteInfo(data);
      } catch (error) {
        console.error('Erro ao buscar informações:', error);
        setLoadingError(`Erro ao buscar informações: ${error.message}`);
      }
    };

    fetchSiteInfo();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSiteInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const { name } = e.target;
    const file = e.target.files[0];

    // Usar um FileReader para ler o arquivo como URL
    const reader = new FileReader();
    reader.onloadend = () => {
      setSiteInfo((prev) => ({ ...prev, [name]: reader.result }));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.arthurcomics.com/api/site-info', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(siteInfo)
      });

      if (!response.ok) {
        throw new Error('Erro ao atualizar informações do site');
      }

      alert('Informações do site atualizadas com sucesso!');
    } catch (error) {
      console.error('Erro ao atualizar informações:', error);
      setLoadingError(`Erro ao atualizar informações: ${error.message}`);
    }
  };

  return (
    <div className="edit-site-info-container">
      <h1>Editar Informações do Site</h1>
      {loadingError && <p className="error">{loadingError}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="title">Título do Site:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={siteInfo.title}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="description">Descrição do Site:</label>
          <textarea
            id="description"
            name="description"
            value={siteInfo.description}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label htmlFor="icon">Ícone:</label>
          <input
            type="file"
            id="icon"
            name="icon"
            accept="image/*"
            onChange={handleFileChange}
          />
          {siteInfo.icon && <img src={siteInfo.icon} alt="Ícone" style={{ width: '50px', height: '50px' }} />}
        </div>
        <div>
          <label htmlFor="logo">Logo:</label>
          <input
            type="file"
            id="logo"
            name="logo"
            accept="image/*"
            onChange={handleFileChange}
          />
          {siteInfo.logo && <img src={siteInfo.logo} alt="Logo" style={{ width: '150px', height: 'auto' }} />}
        </div>
        <button type="submit">Salvar</button>
      </form>
    </div>
  );
};

export default EditSiteInfo;
