import React, { useEffect, useState } from 'react';
import '../style/EditManga.css';

const token = localStorage.getItem('authToken');

function AdManagement() {
  const [ads, setAds] = useState({
    home: '',
    sidebarAds: '',
    projects: '',
    reading: '',
  });
  const [adsIds, setAdsIds] = useState({ // Para armazenar os IDs dos anúncios
    home: null,
    sidebarAds: null,
    projects: null,
    reading: null,
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    // Buscar os anúncios do backend quando o componente carregar
    fetch('https://api.arthurcomics.com/api/ads', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro ao buscar anúncios: ' + response.statusText);
        }
        return response.json();
      })
      .then(data => {
        // Armazena os anúncios como um objeto
        const adsMap = data.reduce((acc, ad) => {
          // Supondo que o adContent armazena o conteúdo de cada seção
          const section = ad.section; // Supondo que a seção esteja definida no ad
          if (section) {
            acc[section] = ad.adContent; // Define o conteúdo do anúncio
            acc.ids[section] = ad._id; // Armazena o ID do anúncio
          }
          return acc;
        }, { ...ads, ids: { home: null, sidebarAds: null, projects: null, reading: null } });

        setAds(adsMap);
        setAdsIds(adsMap.ids);
      })
      .catch(error => {
        console.error('Erro ao buscar anúncios:', error);
        setErrorMessage('Não foi possível buscar os anúncios. Verifique as permissões.');
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAds((prevAds) => ({
      ...prevAds,
      [name]: value,
    }));
  };

  const handleDelete = async (section) => {
    const adId = adsIds[section]; // Obtém o ID do anúncio da seção
    if (!adId) {
      console.log(`Nenhum ID encontrado para a seção ${section}.`);
      return;
    }

    try {
      const response = await fetch(`https://api.arthurcomics.com/api/ads/${adId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Erro ao deletar anúncio da seção ${section}: ${errorData.error || response.statusText}`);
      }

      setSuccessMessage(`Anúncio da seção ${section} excluído com sucesso!`);
      setAds((prevAds) => ({
        ...prevAds,
        [section]: '', // Limpa o textarea após a exclusão
      }));
      setAdsIds((prevIds) => ({
        ...prevIds,
        [section]: null, // Limpa o ID após a exclusão
      }));
    } catch (error) {
      console.error('Erro ao enviar requisição:', error);
      setErrorMessage(`Erro na seção ${section}: ${error.message}`);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Previne o comportamento padrão do formulário

    const sections = ['home', 'sidebarAds', 'projects', 'reading'];

    for (const section of sections) {
      const adContent = ads[section].trim(); // Garante que estamos usando uma string
      const adId = adsIds[section]; // Obtém o ID do anúncio

      try {
        if (adContent === '') {
          // Se o campo estiver vazio, não faz nada
          continue;
        } else {
          if (adId) {
            // Se o ID existir, atualiza o anúncio
            const response = await fetch(`https://api.arthurcomics.com/api/ads/${adId}`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              },
              body: JSON.stringify({ adContent }), // Atualiza o conteúdo do anúncio
            });

            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(`Erro ao atualizar anúncio na seção ${section}: ${errorData.error || response.statusText}`);
            }

            setSuccessMessage(`Anúncio da seção ${section} atualizado com sucesso!`);
          } else {
            // Se o ID não existir, cria um novo anúncio
            const response = await fetch(`https://api.arthurcomics.com/api/ads`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              },
              body: JSON.stringify({ adContent, section }), // Inclui a seção para identificação
            });

            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(`Erro ao salvar anúncios na seção ${section}: ${errorData.error || response.statusText}`);
            }

            const data = await response.json();
            setAdsIds((prevIds) => ({
              ...prevIds,
              [section]: data._id, // Armazena o ID do novo anúncio
            }));
            setSuccessMessage(`Anúncio da seção ${section} salvo com sucesso!`);
          }
        }
      } catch (error) {
        console.error('Erro ao enviar requisição:', error);
        setErrorMessage(`Erro na seção ${section}: ${error.message}`);
      }
    }
  };

  return (
    <div className="ad-management-container">
      <h1>Gerenciar Anúncios</h1>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      {successMessage && <div className="success-message">{successMessage}</div>}
      <form onSubmit={handleSubmit}>
        <div className="ad-section">
          <h2>Anúncios para a Home</h2>
          <textarea
            name="home"
            value={ads.home}
            onChange={handleChange}
            placeholder="Cole seu código de anúncio para a Home (formato <script> ou <img>)"
          />
          <button type="button" onClick={() => handleDelete('home')}>Excluir</button>
        </div>

        <div className="ad-section">
          <h2>Anúncios para a Barra Lateral</h2>
          <textarea
            name="sidebarAds"
            value={ads.sidebarAds}
            onChange={handleChange}
            placeholder="Cole seu código de anúncio para a Barra Lateral (formato <script> ou <img>)"
          />
          <button type="button" onClick={() => handleDelete('sidebarAds')}>Excluir</button>
        </div>

        <div className="ad-section">
          <h2>Anúncios para a Página de Projetos</h2>
          <textarea
            name="projects"
            value={ads.projects}
            onChange={handleChange}
            placeholder="Cole seu código de anúncio para a Página de Projetos (formato <script> ou <img>)"
          />
          <button type="button" onClick={() => handleDelete('projects')}>Excluir</button>
        </div>

        <div className="ad-section">
          <h2>Anúncios para a Página de Leitura</h2>
          <textarea
            name="reading"
            value={ads.reading}
            onChange={handleChange}
            placeholder="Cole seu código de anúncio para a Página de Leitura (formato <script> ou <img>)"
          />
          <button type="button" onClick={() => handleDelete('reading')}>Excluir</button>
        </div>

        <button type="submit">Salvar Anúncios</button>
      </form>
    </div>
  );
}

export default AdManagement;
