// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CreateChapter.css */

  h1 {
    font-size: 24px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  label {
    font-size: 16px;
    color: #f0f0f0;
  }
  
  input, textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    border-radius: 8px;
    font-size: 14px;
    color: #fff;
    background: rgba(255, 255, 255, 0.02);
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
  }
  
  textarea {
    min-height: 150px;
    resize: vertical;
  }
  
  button {
    padding: 12px 24px;
    font-size: 18px;
    margin: 10px;
    width: 100%;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.05);
    border: none;
    border-radius: 8px;
    transition: background 0.3s ease, color 0.3s ease;
    color: #80deea;
}
  
button:hover {
    background: rgba(128, 222, 234, 0.1);
    color: #ffffff;
  }
  
  p {
    text-align: center;
    font-size: 16px;
    color: #4CAF50;
    margin-top: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/style/CreateChapter.css"],"names":[],"mappings":"AAAA,sBAAsB;;EAEpB;IACE,eAAe;IACf,cAAc;IACd,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,SAAS;EACX;;EAEA;IACE,eAAe;IACf,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,eAAe;IACf,2CAA2C;IAC3C,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,qCAAqC;IACrC,mCAAmC;IACnC,2BAA2B;EAC7B;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,WAAW;IACX,eAAe;IACf,qCAAqC;IACrC,YAAY;IACZ,kBAAkB;IAClB,iDAAiD;IACjD,cAAc;AAClB;;AAEA;IACI,oCAAoC;IACpC,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,cAAc;IACd,gBAAgB;EAClB","sourcesContent":["/* CreateChapter.css */\r\n\r\n  h1 {\r\n    font-size: 24px;\r\n    color: #ffffff;\r\n    text-align: center;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  form {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 15px;\r\n  }\r\n  \r\n  label {\r\n    font-size: 16px;\r\n    color: #f0f0f0;\r\n  }\r\n  \r\n  input, textarea {\r\n    width: 100%;\r\n    padding: 10px;\r\n    margin-top: 5px;\r\n    border: 1px solid rgba(255, 255, 255, 0.08);\r\n    border-radius: 8px;\r\n    font-size: 14px;\r\n    color: #fff;\r\n    background: rgba(255, 255, 255, 0.02);\r\n    -webkit-backdrop-filter: blur(12px);\r\n    backdrop-filter: blur(12px);\r\n  }\r\n  \r\n  textarea {\r\n    min-height: 150px;\r\n    resize: vertical;\r\n  }\r\n  \r\n  button {\r\n    padding: 12px 24px;\r\n    font-size: 18px;\r\n    margin: 10px;\r\n    width: 100%;\r\n    cursor: pointer;\r\n    background: rgba(255, 255, 255, 0.05);\r\n    border: none;\r\n    border-radius: 8px;\r\n    transition: background 0.3s ease, color 0.3s ease;\r\n    color: #80deea;\r\n}\r\n  \r\nbutton:hover {\r\n    background: rgba(128, 222, 234, 0.1);\r\n    color: #ffffff;\r\n  }\r\n  \r\n  p {\r\n    text-align: center;\r\n    font-size: 16px;\r\n    color: #4CAF50;\r\n    margin-top: 20px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
